import React from 'react';

import ContentImage from 'components/About/ContentImage';
import type { WordpressImage } from 'types/wordpressImage';
import { ServicesProps } from 'components/About/Services';
import HomepageDiv from 'components/HomepageDiv';

interface Props extends ServicesProps {
	content: string;
	subtitle?: string;
	title: string;
	image: WordpressImage;
}

export default (props: Props) => (
	<HomepageDiv id="about">
		<ContentImage
			image={props?.image?.localFile?.childImageSharp?.gatsbyImageData}
			imageAlt={props?.image?.localFile?.name}
			title={props?.title}
			content={props?.content}
			subtitle={props?.subtitle}
			servicesTitle={props?.servicesTitle}
			servicesSubtitle={props?.servicesSubtitle}
			services={props?.services}
		/>
	</HomepageDiv>
);
