import React from 'react';
import { styled } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import Flex from 'components/Flex';
import ContentImage from 'components/ContentImage';
import Paragraphs from 'components/Paragraphs';
import Services, { ServicesProps } from 'components/About/Services';
import TitleSubtitle, { TitleSubtitleProps } from 'components/TitleSubtitle';

const TextContainer = styled(Flex, { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	margin: reverse ? theme.spacing(0, 0, 0, 10) : theme.spacing(0, 10, 5, 0),
	[theme.breakpoints.down('md')]: {
		margin: theme.spacing(0, 0, 5, 0),
	},
}));

export interface ContentImageProps extends ServicesProps, TitleSubtitleProps {
	image: IGatsbyImageData;
	imageAlt: string;
	content: string | string[];
	reverse?: boolean;
}

export default (props: ContentImageProps) => (
	<ContentImage image={props?.image} imageAlt={props?.imageAlt} reverse={props?.reverse}>
		<Flex direction="column">
			<TextContainer direction="column" reverse={props?.reverse}>
				<TitleSubtitle title={props?.title} subtitle={props?.subtitle} />
				<Paragraphs content={props?.content} />
			</TextContainer>
			<Services
				servicesTitle={props?.servicesTitle}
				servicesSubtitle={props?.servicesSubtitle}
				services={props?.services}
			/>
		</Flex>
	</ContentImage>
);
