import React from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';

const HomepageDiv = styled(Flex)(({ theme }) => ({
	padding: theme.spacing(15, 20),
	[theme.breakpoints.down('lg')]: {
		padding: theme.spacing(15, 10),
	},
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(5, 3),
	},
}));

interface Props {
	children: React.ReactNode;
	id?: string;
	className?: string;
	row?: boolean;
}

export default (props: Props) => (
	<HomepageDiv
		direction={props?.row ? 'row' : 'column'}
		id={props?.id}
		align="center"
		className={props?.className}
	>
		{props?.children}
	</HomepageDiv>
);
