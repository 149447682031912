import React from 'react';
import { styled, IconButton, useTheme, useMediaQuery } from '@mui/material';

import { ReactComponent as LeftArrowIcon } from 'content/images/icons/arrow-left-long.svg';
import { ReactComponent as RightArrowIcon } from 'content/images/icons/arrow-right-long.svg';
import Flex from 'components/Flex';

const LeftArrow = styled(LeftArrowIcon)({
	fill: 'white',
	width: 25,
});

const RightArrow = styled(RightArrowIcon)({
	fill: 'white',
	width: 25,
});

const ButtonsRoot = styled(Flex)(({ theme }) => ({
	marginLeft: theme.spacing(5),
}));

const Button = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'left' })<{
	left?: boolean;
}>(({ theme, left }) => ({
	marginRight: left ? theme.spacing(2) : 0,
}));

interface Props {
	handleButtonLeft: () => void;
	handleButtonRight: () => void;
}

export default (props: Props) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	const renderButton = (direction: 'left' | 'right') => (
		<Button
			onClick={
				direction === 'left'
					? () => props?.handleButtonLeft()
					: () => props?.handleButtonRight()
			}
			left={direction === 'left'}
		>
			{direction === 'left' ? <LeftArrow /> : <RightArrow />}
		</Button>
	);

	if (!mobile) {
		return (
			<ButtonsRoot defaultFlex>
				{renderButton('left')}
				{renderButton('right')}
			</ButtonsRoot>
		);
	}

	return null;
};
