import React, { useRef, useEffect, forwardRef } from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';
import TitleSubtitle, { TitleSubtitleProps } from 'components/TitleSubtitle';
import Buttons from 'components/Gallery/Buttons';

const ProjectsRoot = styled(Flex)(({ theme }) => ({
	background: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, #393b3e 100%);`,
	padding: theme.spacing(15, 0),
	[theme.breakpoints.down('lg')]: {
		padding: theme.spacing(15, 0),
	},
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(5, 0),
	},
}));

const TileContainer = styled(Flex)(({ theme }) => ({
	overflowX: 'hidden',
	[theme.breakpoints.down('md')]: {
		overflowX: 'scroll',
	},
}));

const Content = styled(Flex)({
	overflowX: 'hidden',
});

const HeaderRow = styled(Flex)(({ theme }) => ({
	marginBottom: theme.spacing(5),
	padding: theme.spacing(0, 20),
	[theme.breakpoints.down('lg')]: {
		padding: theme.spacing(0, 10),
	},
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(0, 3),
	},
}));

interface Props extends TitleSubtitleProps {
	children: React.ReactNode;
	scroll: number;
	setScroll: (scroll: number) => void;
	id?: string;
}

export default forwardRef((props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
	const containerRef = useRef<HTMLDivElement>();
	const containerWidth = useRef(0);
	const maxScroll = useRef(0);

	const handleButtonClick = (direction: 'left' | 'right') => {
		if (containerWidth?.current && typeof props?.scroll === 'number' && props.setScroll) {
			if (direction === 'left') {
				const newScroll = props.scroll - containerWidth.current;
				if (newScroll < 0) {
					props.setScroll(0);
				} else {
					props.setScroll(newScroll);
				}
			} else if (direction === 'right') {
				const newScroll = props.scroll + containerWidth.current;
				if (newScroll > maxScroll.current - containerWidth.current) {
					props.setScroll(maxScroll.current - containerWidth.current);
				} else {
					props.setScroll(newScroll);
				}
			}
		}
	};

	useEffect(() => {
		if (containerRef?.current) {
			containerWidth.current = containerRef?.current?.getBoundingClientRect()?.width;
		}

		// @ts-ignore type issues with refs
		if (ref?.current) {
			maxScroll.current = ref?.current?.getBoundingClientRect()?.width;
		}
	}, []);

	return (
		<ProjectsRoot direction="column" align="center" id={props?.id}>
			<Content direction="column" width ref={containerRef}>
				<HeaderRow justify="space-between" width align="flex-end">
					<Flex direction="column">
						<TitleSubtitle
							title={props?.title}
							subtitle={props?.subtitle}
							white
							noParagraph
						/>
					</Flex>
					<Buttons
						handleButtonLeft={() => handleButtonClick('left')}
						handleButtonRight={() => handleButtonClick('right')}
					/>
				</HeaderRow>
				<TileContainer width>{props?.children}</TileContainer>
			</Content>
		</ProjectsRoot>
	);
});
