import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

import type { HomepageData } from 'types/homepageData';
import Flex from 'components/Flex';
import Div from 'components/Hero/Div';

const BadgeContainerRoot = styled(Div)(({ theme }) => ({
	position: 'absolute',
	bottom: -30,
	right: 0,
	zIndex: 2,
	justifyContent: 'flex-end',
	[theme.breakpoints.down('sm')]: {
		justifyContent: 'center',
	},
}));

const BadgeContainerPaper = styled(Flex)(({ theme }) => ({
	background: `linear-gradient(0deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.main} 3%, rgba(255,255,255,1) 3%, rgba(255,255,255,1) 100%)`,
	padding: theme.spacing(4, 10),
	borderRadius: 2,
	boxShadow: theme.shadows[3],
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(3, 5),
		width: '100%',
		justifyContent: 'space-between',
	},
	[theme.breakpoints.down('xs')]: {
		padding: theme.spacing(3, 2),
	},
}));

const Badge = styled(GatsbyImage)(({ theme }) => ({
	height: 75,
	marginRight: theme.spacing(4),
	'&:last-of-type': {
		marginRight: 0,
	},
	[theme.breakpoints.down('sm')]: {
		height: 50,
		marginRight: 1,
		'&:last-of-type': {
			marginRight: 0,
		},
	},
	[theme.breakpoints.down('xs')]: {
		height: 35,
	},
}));

export interface BadgeContainerProps {
	badges: HomepageData['allWpCertificationBadge']['nodes'];
}

export default (props: BadgeContainerProps) => {
	const sortedBadges = props?.badges?.sort(
		(a, b) =>
			(a?.certificationBadge?.orderNumber === null) -
				(b?.certificationBadge?.orderNumber === null) ||
			+(a?.certificationBadge?.orderNumber > b?.certificationBadge?.orderNumber) ||
			-(a?.certificationBadge?.orderNumber < b?.certificationBadge?.orderNumber),
	);

	const renderBadges = () =>
		sortedBadges?.map((badge) => (
			<Badge
				image={
					badge?.certificationBadge?.image?.localFile?.childImageSharp?.gatsbyImageData
				}
				alt={badge?.certificationBadge?.image?.localFile?.name}
				key={badge?.certificationBadge?.image?.localFile?.name}
				objectFit="contain"
			/>
		));

	return (
		<BadgeContainerRoot align="center" width>
			<BadgeContainerPaper defaultFlex>{renderBadges()}</BadgeContainerPaper>
		</BadgeContainerRoot>
	);
};
