import React from 'react';
import { styled, Typography } from '@mui/material';

const Content = styled(Typography, { shouldForwardProp: (prop) => prop !== 'last' })<{
	last?: boolean;
}>(({ theme, last }) => ({
	marginBottom: last ? 0 : theme.spacing(3),
}));

interface Props {
	content: string | string[];
}

export default (props: Props) => {
	if (Array.isArray(props?.content)) {
		return (
			<>
				{props?.content?.map((string, index) => (
					<Content key={string} last={index === props?.content?.length - 1}>
						{string}
					</Content>
				))}
			</>
		);
	}
	return <Content last>{props?.content}</Content>;
};
