import React from 'react';

import Content, { ContentProps } from 'components/HomepageHero/Content';
import Hero, { HeroProps } from 'components/Hero';

interface Props extends ContentProps, HeroProps {}

export default (props: Props) => (
	<Hero backgroundImage={props?.backgroundImage} badges={props?.badges}>
		<Content
			title={props?.title}
			subtitle={props?.subtitle}
			buttonText={props?.buttonText}
			buttonUrl={props?.buttonUrl}
		/>
	</Hero>
);
