import React from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';
import TitleSubtitle from 'components/TitleSubtitle';
import TitleContent from 'components/TitleContent';

const ServicesRoot = styled(Flex)(({ theme }) => ({
	background: `linear-gradient(0deg, #21212e 0%, ${theme.palette.primary.main} 100%);`,
	boxShadow: theme.shadows[3],
	padding: theme.spacing(7, 4),
	borderRadius: 10,
	maxWidth: 600,
	[theme.breakpoints.down('md')]: {
		alignSelf: 'center',
	},
}));

const Icon = styled('img')(({ theme }) => ({
	fill: 'white',
	width: 40,
	marginRight: theme.spacing(2),
	filter: 'invert(100%)',
}));

const ServiceRoot = styled(Flex)(({ theme }) => ({
	margin: theme.spacing(2, 0),
}));

const Divider = styled('div')(({ theme }) => ({
	content: '""',
	background: 'white',
	height: 2,
	width: '100%',
	margin: theme.spacing(2, 0),
}));

export interface ServiceNodes {
	nodes: {
		title: string;
		services: {
			content: string;
			icon: {
				sourceUrl: string;
			};
			orderNumber: number | null;
		};
	}[];
}

export interface ServicesProps {
	servicesTitle: string;
	servicesSubtitle?: string;
	services: ServiceNodes;
}

export default (props: ServicesProps) => {
	const renderServices = () =>
		props?.services?.nodes?.map((service) => (
			<Flex direction="column" key={`service${service?.title}`}>
				<ServiceRoot align="center">
					<Icon src={service?.services?.icon?.sourceUrl} alt="" />
					<Flex direction="column">
						<TitleContent title={service?.title} content={service?.services?.content} />
					</Flex>
				</ServiceRoot>
				<Divider />
			</Flex>
		));

	return (
		<ServicesRoot direction="column" id="services">
			<TitleSubtitle subtitle={props?.servicesSubtitle} title={props?.servicesTitle} white />
			{renderServices()}
		</ServicesRoot>
	);
};
