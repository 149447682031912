import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from 'layouts/Base';
import HomepageHero from 'components/HomepageHero';
import About from 'components/About';
import type { HomepageData } from 'types/homepageData';
import Projects from 'components/Projects';

export default ({ data }: { data: HomepageData }) => {
	const home = data?.wpSiteSection;

	return (
		<BaseLayout
			title={home.seo?.title}
			metaTitle={home.seo?.title}
			metaDescription={home.seo?.metaDesc}
		>
			<HomepageHero
				backgroundImage={
					home.homepage?.heroImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				title={home?.homepage?.heroTitle}
				subtitle={home?.homepage?.heroSubtitle}
				buttonText={home?.homepage?.heroButtonText}
				buttonUrl={home?.homepage?.heroButtonUrl}
				badges={data?.allWpCertificationBadge?.nodes}
			/>
			<About
				content={home?.homepage?.aboutSectionContent}
				subtitle={home?.homepage?.aboutSectionSubtitle}
				title={home?.homepage?.aboutSectionTitle}
				image={home?.homepage?.aboutSectionImage}
				servicesTitle={home?.homepage?.servicesSectionTitle}
				servicesSubtitle={home?.homepage?.servicesSectionSubtitle}
				services={data?.allWpService}
			/>
			<Projects
				title={home?.homepage?.projectsSectionTitle}
				subtitle={home?.homepage?.projectsSectionSubtitle}
				projects={data?.allWpProjectWriteup?.nodes}
			/>
		</BaseLayout>
	);
};

export const data = graphql`
	query Homepage {
		wpSiteSection(title: { eq: "Homepage" }) {
			title
			homepage {
				aboutSectionContent
				aboutSectionSubtitle
				aboutSectionImage {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				aboutSectionTitle
				contactSectionSubtitle
				contactSectionTitle
				heroButtonText
				heroButtonUrl
				heroImage {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				heroSubtitle
				heroTitle
				projectsSectionSubtitle
				projectsSectionTitle
				servicesSectionSubtitle
				servicesSectionTitle
			}
			seo {
				metaDesc
				title
			}
		}
		allWpCertificationBadge {
			nodes {
				certificationBadge {
					image {
						localFile {
							name
							childImageSharp {
								gatsbyImageData(height: 75, layout: CONSTRAINED)
							}
						}
					}
					orderNumber
				}
			}
		}
		allWpService {
			nodes {
				title
				services {
					content
					icon {
						sourceUrl
					}
					orderNumber
				}
			}
		}
		allWpProjectWriteup {
			nodes {
				title
				projectWriteup {
					subtitle
					heroTextContent
					heroImage {
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
				id
				slug
			}
		}
	}
`;
