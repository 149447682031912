import React from 'react';
import { styled } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import DarkenedBackgroundImage from 'components/DarkenedBackgroundImage';
import BadgeContainer, { BadgeContainerProps } from 'components/Hero/BadgeContainer';

const HeroRoot = styled('div')({
	position: 'relative',
});

const HeroImage = styled(DarkenedBackgroundImage)({
	minHeight: 700,
	webkitClipPath: 'polygon(100% 0%, 100% 95%, 0% 100%, 0% 0%)',
	clipPath: 'polygon(100% 0%, 100% 90%, 0% 100%, 0% 0%)',
});

const Square = styled('div')(({ theme }) => ({
	position: 'absolute',
	bottom: -30,
	left: 0,
	width: '100%',
	height: 200,
	content: '""',
	backgroundColor: theme.palette.tertiary.main,
	zIndex: -1,
	webkitClipPath: 'polygon(100% 0%, 100% 100%, 0% 85%, 0% 0%)',
	clipPath: 'polygon(100% 0%, 100% 100%, 0% 85%, 0% 0%)',
}));

export interface HeroProps {
	backgroundImage: IGatsbyImageData;
	badges?: BadgeContainerProps['badges'];
}

interface Props extends HeroProps {
	children: React.ReactNode;
}

export default (props: Props) => {
	const renderBadges = () => {
		if (props?.badges) {
			return <BadgeContainer badges={props.badges} />;
		}
		return null;
	};

	return (
		<HeroRoot>
			{renderBadges()}
			<HeroImage image={props?.backgroundImage} navPadding>
				{props?.children}
			</HeroImage>
			<Square />
		</HeroRoot>
	);
};
