import React, { forwardRef } from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';
import Tile, { TileProps } from 'components/Projects/Tile';

const TilesRoot = styled(Flex, { shouldForwardProp: (prop) => prop !== 'scroll' })<{
	scroll: number;
}>(({ scroll }) => ({
	transform: `translateX(-${scroll}px)`,
	transition: 'all 1s ease',
}));

interface Props {
	tiles: TileProps[];
	scroll: number;
}

export default forwardRef((props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
	const marginArray = [47, 0, 69, 103, 47, 137, 103];

	const renderTiles = () =>
		props?.tiles?.map((tile, index) => {
			const returnMargin = () => {
				// Don't divide by zero please
				const divisor = index === 0 ? 0 : Math.floor(index / 7) * 7;
				return index - divisor;
			};

			return (
				<Tile tile={tile} margin={marginArray[returnMargin()]} key={`tile${tile?.title}`} />
			);
		});

	return (
		<TilesRoot ref={ref} scroll={props?.scroll}>
			{renderTiles()}
		</TilesRoot>
	);
});
