import React from 'react';
import { styled, Typography } from '@mui/material';
import { BgImage } from 'gbimage-bridge';
import { Button } from 'gatsby-theme-material-ui';

import Flex from 'components/Flex';
import type { WordpressImage } from 'types/wordpressImage';
import { ReactComponent as ChevronRightIcon } from 'content/images/icons/chevron-right.svg';

const ChevronRight = styled(ChevronRightIcon)(({ theme }) => ({
	width: 8,
	marginLeft: theme.spacing(1),
	fill: 'white',
}));

const TileButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'margin' })<{
	margin: number;
}>(({ theme, margin }) => ({
	marginTop: margin,
	marginRight: theme.spacing(3),
	padding: 0,
	'&:last-of-type': {
		marginRight: 0,
	},
}));

const ProjectTile = styled(BgImage)({
	width: 300,
	height: 600,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});

const Content = styled(Flex)(({ theme }) => ({
	opacity: 0,
	transition: 'all 0.5s ease',
	background: 'rgba(0, 0, 0, 0.7)',
	height: '100%',
	padding: theme.spacing(3),
	justifyContent: 'center',
	'&:hover': {
		opacity: '100%',
	},
}));

const Text = styled(Typography)({
	color: 'white',
});

export interface TileProps {
	title: string;
	projectWriteup: {
		subtitle: string | null;
		heroTextContent: string | null;
		heroImage: WordpressImage;
	};
	id: string;
	slug: string;
}

interface Props {
	tile: TileProps;
	margin: number;
}

export default (props: Props) => {
	const renderContent = () => {
		if (props?.tile?.projectWriteup?.heroTextContent) {
			return <Text paragraph>{props?.tile?.projectWriteup?.heroTextContent}</Text>;
		}
		return null;
	};

	return (
		<TileButton margin={props?.margin} to={`/${props?.tile?.slug}`}>
			<ProjectTile
				image={
					props?.tile?.projectWriteup?.heroImage?.localFile?.childImageSharp
						?.gatsbyImageData
				}
			>
				<Content direction="column" width justify="center">
					<Text variant="h5" paragraph>
						{props?.tile?.title}
					</Text>
					{renderContent()}
					<Flex defaultFlex align="center">
						<Text>Read More</Text>
						<ChevronRight />
					</Flex>
				</Content>
			</ProjectTile>
		</TileButton>
	);
};
