import React, { useState, useEffect } from 'react';
import { styled, Typography, Collapse } from '@mui/material';

import Flex from 'components/Flex';
import Button from 'components/Button';
import SideAlignedContainer from 'components/SideAlignedContainer';

const ContentRoot = styled(Flex)({
	maxWidth: 850,
	marginTop: 106,
});

const Text = styled(Typography, { shouldForwardProp: (prop) => prop !== 'margin' })<{
	margin: number;
}>(({ theme, margin }) => ({
	color: 'white',
	marginBottom: theme.spacing(margin),
}));

const Title = styled(Text)({
	fontWeight: 800,
});

export interface ContentProps {
	title?: string;
	subtitle?: string;
	buttonText: string;
	buttonUrl: string;
}

export default (props: ContentProps) => {
	const [titleIn, setTitleIn] = useState(false);
	const [subtitleIn, setSubtitleIn] = useState(false);
	const [buttonIn, setButtonIn] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setTitleIn(true);
		}, 500);

		setTimeout(() => {
			setSubtitleIn(true);
		}, 1000);

		setTimeout(() => {
			setButtonIn(true);
		}, 1500);
	}, []);

	return (
		<ContentRoot direction="column">
			<SideAlignedContainer navMargin side="left">
				<Collapse in={titleIn}>
					<Title variant="h1" margin={2}>
						{props?.title}
					</Title>
				</Collapse>
				<Collapse in={subtitleIn}>
					<Text variant="subtitle1" margin={2}>
						{props?.subtitle}
					</Text>
				</Collapse>
				<Collapse in={buttonIn}>
					<Button to={props?.buttonUrl} color="primary">
						{props?.buttonText}
					</Button>
				</Collapse>
			</SideAlignedContainer>
		</ContentRoot>
	);
};
