import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import Flex from 'components/Flex';
import aboutBlob from 'content/images/about-blob.svg';

const ImageContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	padding: reverse ? theme.spacing(4, 4, 0, 0) : theme.spacing(4, '75px', 0, 4),
	position: 'relative',
	[theme.breakpoints.down('md')]: {
		padding: reverse ? theme.spacing(4, 0, 0) : theme.spacing(4, 5, 0, 0),
	},
	[theme.breakpoints.down('sm')]: {
		marginBottom: 75,
	},
}));

const Image = styled(GatsbyImage)(({ theme }) => ({
	maxWidth: 500,
	maxHeight: 600,
	flex: '1 1 0',
	overflow: 'visible',
	zIndex: 2,
	[theme.breakpoints.down('lg')]: {
		maxWidth: 300,
	},
	[theme.breakpoints.down('md')]: {
		flex: 1,
		maxWidth: 500,
		maxHeight: 400,
		width: '100%',
	},
}));

const Shadow = styled('div')(({ theme }) => ({
	position: 'absolute',
	bottom: -75,
	right: -0,
	background: theme.palette.secondary.main,
	zIndex: 1,
	width: '100%',
	height: '100%',
	maxWidth: 500,
	maxHeight: 600,
	flex: '1 1 0',
	borderRadius: 40,
	content: '""',
	maskImage: `url(${aboutBlob})`,
	maskRepeat: 'no-repeat',
	maskPosition: 'center',
	maskSize: '100% 100%',
	transform: 'scaleX(-1)',
	[theme.breakpoints.down('lg')]: {
		maxWidth: 300,
	},
	[theme.breakpoints.down('md')]: {
		flex: 1,
		maxWidth: 500,
		maxHeight: 400,
		width: `calc(100% - ${theme.spacing(5)})`,
	},
}));

const ContentContainer = styled(Flex, { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	width: '100%',
	maxWidth: 1400,
	flexDirection: reverse ? 'row-reverse' : 'row',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		alignItems: 'center',
		margin: theme.spacing(3, 0, 0),
	},
}));

interface Props {
	image: IGatsbyImageData;
	imageAlt: string;
	children: React.ReactNode;
	reverse?: boolean;
	id?: string;
}

export default (props: Props) => (
	<ContentContainer justify="space-evenly" align="center" reverse={props?.reverse} id={props?.id}>
		{props?.children}
		<ImageContainer reverse={props?.reverse}>
			<Image
				image={props?.image}
				alt={props?.imageAlt}
				imgStyle={{
					maskImage: `url(${aboutBlob})`,
					WebkitMaskImage: `url(${aboutBlob})`,
					maskRepeat: 'no-repeat',
					maskPosition: 'center',
					maskSize: '100% 100%',
				}}
			/>
			<Shadow />
		</ImageContainer>
	</ContentContainer>
);
