import React, { useRef, useState } from 'react';

import { TitleSubtitleProps } from 'components/TitleSubtitle';
import Tiles from 'components/Projects/Tiles';
import Gallery from 'components/Gallery';
import type { HomepageData } from 'types/homepageData';

interface Props extends TitleSubtitleProps {
	projects: HomepageData['allWpProjectWriteup']['nodes'];
}

export default (props: Props) => {
	const [scroll, setScroll] = useState(0);
	const projectsRef = useRef<HTMLDivElement>();

	return (
		<Gallery
			title={props?.title}
			subtitle={props?.subtitle}
			white
			noParagraph
			ref={projectsRef}
			scroll={scroll}
			setScroll={setScroll}
			id="projects"
		>
			<Tiles tiles={props?.projects} scroll={scroll} ref={projectsRef} />
		</Gallery>
	);
};
